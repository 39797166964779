
import {
  defineComponent,
  reactive,
  ref,
  UnwrapRef,
  onMounted,
  createVNode,
} from "vue";
import { notification, Modal } from "ant-design-vue";
import { ValidateErrorEntity } from "ant-design-vue/es/form/interface";
import validate from "../services/validator";
import candidateService from "../services/candidate";
import commonService from "../services/common";
import authService from "../services/auth";
import {
  CheckCircleOutlined,
  EditOutlined,
  DeleteOutlined,
  CloseCircleOutlined,
} from "@ant-design/icons-vue";
import _ from "lodash";

interface Iuser {
  first_name: string;
  last_name: string;
  email: string;
  role_id: number;
  userstatus: boolean;
}

export default defineComponent({
  emits: ["updateProfile"],
  components: {
    EditOutlined,
    DeleteOutlined,
  },
  setup(props, { emit }) {
    const userTabKey = ref("1");
    const saveText = ref();
    const userLists = ref([]);
    const contactPerson = ref([]);
    const showEditForm = ref<boolean>(false);
    const updateLoading = ref<boolean>(false);
    const contactId = ref()
    const userColumns = [
      {
        title: "Full Name",
        dataIndex: "full_name",
      },
      {
        title: "Email",
        dataIndex: "email",
      },
      {
        title: "Permission",
        dataIndex: "role_name",
      },
      {
        title: "Status",
        dataIndex: "status",
        slots: { customRender: "status" },
      },
      {
        title: "Action",
        align: "center",
        slots: { customRender: "action" },
      },
    ];
    const addEditUserTitle = ref();
    const edituserId = ref();
    const allUserPermissions = ref([]);
    const creatUserVisible = ref<boolean>(false);
    const confirmLoading = ref<boolean>(false);
    const pageLoading = ref<boolean>(false);
    const permissionLoading = ref<boolean>(false);
    const userFormRef = ref();
    const showPermissionTab = ref<boolean>(false);
    const userFormRules = validate.addUserValidation;
    const userFormModal: UnwrapRef<Iuser> = reactive({
      first_name: "",
      last_name: "",
      email: "",
      role_id: null,
      userstatus: null,
    });
    const notify = async (
      message: string,
      description: string,
      type: string
    ) => {
      (notification as any)[type]({
        message,
        description,
      });
    };
    const createNewUser = async () => {
      addEditUserTitle.value = "Add User";
      userTabKey.value = "1";
      userFormModal.first_name = "";
      userFormModal.last_name = "";
      userFormModal.email = "";
      userFormModal.role_id = null;
      userFormModal.userstatus = false;
      creatUserVisible.value = true;
      saveText.value = "Submit";
    };
    const cancelUserModal = async () => {
      creatUserVisible.value = false;
    };
    const addNewUser = async (data: any) => {
      userFormRef.value
        .validate()
        .then(() => {
          saveNewUser(data);
        })
        .catch((error: ValidateErrorEntity<Iuser>) => {
          console.log("error", error);
        });
    };
    const saveNewUser = async (data: any) => {
      data.status = data.userstatus === true ? "active" : "inactive";
      confirmLoading.value = true;
      try {
        let res;
        if (addEditUserTitle.value === "Add User") {
          res = await candidateService.addNewUser(data);
        } else {
          const id = edituserId.value;
          res = await candidateService.editUser(id, data);
        }
        if (res.data.data.success) {
          if (userFormModal.role_id != 2) {
            if (
              res.data.data.message != "Role cannot be update" &&
              res.data.data.message != "Status Cannot be change"
            ) {
              showPermissionTab.value = true;
              userTabKey.value = "2";
              saveText.value = "Update";
              if (addEditUserTitle.value === "Add User") {
                edituserId.value = res.data.data.user_id;
              }
              getUserPermission(edituserId.value);
              await notify("Success", res.data.data.message, "success");
              updateCurrentUserprofile();
            } else {
              await notify("Error", res.data.data.message, "error");
            }
          } else {
            await notify("Success", res.data.data.message, "success");
            userFormRef.value.resetFields();
            creatUserVisible.value = false;
            updateCurrentUserprofile();
          }
          getAllUsersList();
        }
        confirmLoading.value = false;
      } catch (error: any) {
        if (error.response) {
          console.log(error.response);
          confirmLoading.value = false;
          if (error.response.data.email[0]) {
            await notify("Error", error.response.data.email[0], "error");
          } else {
            await notify("Error", error.response.data.data.message, "error");
          }
        }
      }
    };
    const updateCurrentUserprofile = async () => {
      const loggedUserId = JSON.parse(localStorage.getItem("profile"));
      if (edituserId.value == loggedUserId.id) {
        try {
          const responce = await authService.getProfile();
          if (responce.data.data.success) {
            const data = responce.data.data.profile;
            localStorage.profile = JSON.stringify(data);
            emit("updateProfile");
          }
        } catch (error: any) {
          if (error.response) {
            console.log(error.response);
          }
        }
      }
    };
    const editUser = async (data: any) => {
      addEditUserTitle.value = "Edit User";
      saveText.value = "Update";
      userTabKey.value = "1";
      userFormModal.first_name = data.first_name;
      userFormModal.last_name = data.last_name;
      userFormModal.email = data.email;
      userFormModal.role_id = data.role_id.toString();
      userFormModal.userstatus = data.status === "active" ? true : false;
      edituserId.value = data.id;
      creatUserVisible.value = true;
      showPermissionTab.value = false;
      if (data.role_id !== 2) {
        showPermissionTab.value = true;
        getUserPermission(data.id);
      }
    };
    const getAllUsersList = async () => {
      pageLoading.value = true;
      try {
        const res = await candidateService.getAllUser();
        if (res.data.data.success) {
          userLists.value = res.data.data.users;
          userLists.value.map((el) => {
            el.full_name = el.first_name + " " + el.last_name;
          });
          contactPerson.value = res.data.data.contact_person;
          // contactPerson.value.map((el) => {
          //   el.full_name = el.first_name + " " + el.last_name;
          // });
        }
        pageLoading.value = false;
      } catch (error: any) {
        if (error.response) {
          console.log(error.response);
          pageLoading.value = false;
        }
      }
    };
    const showDeleteConfirm = async (userId: any) => {
      Modal.confirm({
        title: "Are you sure?",
        icon: createVNode(CloseCircleOutlined),
        content: createVNode(
          "p",
          {},
          "Do you really want to delete this user? This process cannot be undone."
        ),
        okText: "Delete",
        okType: "danger",
        cancelText: "Cancel",
        class: "delte-confirm-modal",
        async onOk() {
          await deleteUser(userId);
        },
      });
    };
    const deleteUser = async (userId: any) => {
      try {
        const res = await candidateService.deleteUser(userId);
        if (res.data.data.success) {
          await notify("Success", res.data.data.message, "success");
          getAllUsersList();
        }
      } catch (error: any) {
        if (error.response) {
          console.log(error.response);
        }
      }
    };
    const getUserPermission = async (id: any) => {
      try {
        const res = await candidateService.getUserPermission(id);
        if (res.data.data.success) {
          allUserPermissions.value = res.data.data.permissions;
        }
      } catch (error: any) {
        if (error.response) {
          console.log(error.response);
        }
      }
    };

    const getAllIds = async () => {
      const ids: any[] = [];
      _.each(allUserPermissions.value, function (a) {
        if (a.status === true) {
          _.each(a.data, function (key, value) {
            if (key.value === true) {
              ids.push(key.id);
            }
          });
        }
      });
      return ids;
    };
    const updateUserPermission = async () => {
      permissionLoading.value = true;
      const pIds = await getAllIds();
      const data = {
        permission_ids: pIds,
        user_id: edituserId.value,
      };
      try {
        const res = await candidateService.addUserPermission(data);
        if (res.data.data.success) {
          notify("Success", "User permission has been Updated.", "success");
          creatUserVisible.value = false;
        }
        permissionLoading.value = false;
      } catch (error: any) {
        if (error.response) {
          console.log(error.response);
          await notify("Error", error.response.data.message, "error");
          permissionLoading.value = false;
        }
      }
    };
    const editContactPerson = async () => {
      contactId.value =''
      showEditForm.value = !showEditForm.value
    };
    const updateContactPerson = async () => {
      const profile = JSON.parse(localStorage.getItem("profile"));
      if(contactId.value) {
        updateLoading.value = true;
         const data = {
           contact_person_id: contactId.value
         }
        try {
          const res = await candidateService.updateContactPerson(profile.agency_id, data);
          if (res.data.data.success) {
            showEditForm.value = false
            await notify("Success", res.data.data.message, "success");
            getAllUsersList();
          }
          updateLoading.value = false;
        } catch (error: any) {
          if (error.response) {
            console.log(error.response);
            await notify("Error", error.response.data.data.message, "error");
            updateLoading.value = false;
          }
        }
      } else {
        await notify("Error", "Please select any one contact", "error");
      }
    }
    onMounted(async () => {
      saveText.value = "Submit";
      getAllUsersList();
    });
    return {
      saveText,
      userColumns,
      userLists,
      contactPerson,
      addEditUserTitle,
      createNewUser,
      creatUserVisible,
      userFormRef,
      userFormRules,
      userFormModal,
      cancelUserModal,
      addNewUser,
      confirmLoading,
      pageLoading,
      notify,
      showDeleteConfirm,
      editUser,
      edituserId,
      userTabKey,
      getUserPermission,
      allUserPermissions,
      updateUserPermission,
      permissionLoading,
      commonService,
      showPermissionTab,
      updateCurrentUserprofile,
      editContactPerson,
      showEditForm,
      contactId,
      updateContactPerson,
      updateLoading,
      labelCol: {
        span: 8,
      },
      wrapperCol: {
        span: 16,
      },
    };
  },
});
